.visual {
  height: 50%;
  min-height: 300px;
  width: 100vw;
  margin: 0;
  overflow: hidden;
  position: relative;

  &-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .logo {
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
  }

  .icon {
    width: 75px;
    height: 75px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 40px;
    top: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.abgelaufen {
  line-height: 36px;
}

.ergebnis {
  height: 50%;
  min-height: 50vh;
  width: 100vw;
  margin: 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  //padding-top: 1.5rem;

  display: grid;
  grid-template-rows: calc(100% - 60px) 60px;
  grid-template-columns: 1fr;

  &::after {
    content: '';
    position: absolute;
    bottom: 40px;
    right: -40px;
    border-radius: 20px;
    background-color: #f59c00;
    width: 80px;
    height: 164px;
    z-index: 0;
  }
  &::before {
    content: '';
    position: absolute;
    top: -40px;
    left: -20px;
    border-radius: 20px;
    background-color: #bccf00;
    width: 80px;
    height: 164px;
    z-index: 0;
  }

  .message {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    p {
      max-width: 284px;
    }
  }

  .big {
    font-weight: bold;
    font-size: 32pt;
    display: block;
    line-height: 42px;
  }

  .links {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0;
    padding: 0;

    li {
      display: inline;

      a {
        img {
          margin-right: 10px;
        }
        display: flex;
        justify-content: center;
        align-items: center;
        color: #1da7e0;
        text-decoration: none;
        font-size: 16pt;
      }
    }
  }
}

.spinner {
  display: block;
  width: 50px;
  height: 50px;
  border-top: 4px solid #1da7e0;
  border-left: 2px solid #1da7e0;
  border-right: 2px solid #1da7e0;
  border-radius: 50%;
  animation: Spinner-spin infinite 2s linear;
}

@keyframes Spinner-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btns {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  width: 100%;
  button {
    font-size: 26px;
  }
}

.slideUp {
  animation-name: slideUp;
  animation-duration: 0.2s;
  animation-timing-function: ease-out;
  animation-delay: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  position: relative;
  top: 15px;
}

@keyframes slideUp {
  0% {
    top: 15px;
  }
  100% {
    top: 0px;
  }
}

.fadeIn {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-delay: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
