/* dosis-regular - latin */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('/statics/fonts/dosis-v19-latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('/statics/fonts/dosis-v19-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* dosis-700 - latin */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 700;
  src: local(''),
    url('/statics/fonts/dosis-v19-latin-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('/statics/fonts/dosis-v19-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

html,
body {
  background-color: #fff;
  color: #1da7e0;
  height: 100%;
  margin: 0;
  font-family: 'Dosis';
  font-size: 20px;
}

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}
@supports (-webkit-touch-callout: none) {
  .App {
    /* The hack for Safari */
    height: -webkit-fill-available;
  }
}

h1,
p {
  margin: 10px 0;
}

p {
  line-height: 24px;
}

h1 {
  line-height: 36px;
}
